.filter-bar {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  gap: 1rem;
  align-items: center;
}

.select {
  width: 200px;
}

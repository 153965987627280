.MuiInput-underline:before {
	border-bottom: 0px !important;
}

.MuiInput-underline:after {
	border-bottom: 0px !important;
}

.MuiInput-input {
	padding: 15px 5px !important;
}

#box {
	padding-top: 15px;
}
